import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface TasksKeyType {
  addTags: LocalesKey;
  all: LocalesKey;
  alreadyMember: LocalesKey;
  acceptResults: LocalesKey;
  active: LocalesKey;
  areYouSureYouWantToDeleteThisTaskYouWillNotBeAbleToUndoThisAction: LocalesKey;
  areYouCertainAboutLeavingTheTaskPleaseBeAwareOfTheFollowingOutcomes: LocalesKey;
  areYouSureYouWantToStartTheTask: LocalesKey;
  cell: LocalesKey;
  channels: LocalesKey;
  confirmAndStart: LocalesKey;
  cannotStartTaskWithNoItemsBoundToTheCurrentSmartContract: LocalesKey;
  cannotCheckInTheSmartContractWithNoShares: LocalesKey;
  cautionItemsInSmartContractAreNotBoundToTheDepositInvoiceAndYouShallStartTheTaskAtYourOwnRisk: LocalesKey;
  coefficient: LocalesKey;
  duplicate: LocalesKey;
  finalResult: LocalesKey;
  fullyPaid: LocalesKey;
  id: LocalesKey;
  hide: LocalesKey;
  list: LocalesKey;
  plural: LocalesKey;
  singular: LocalesKey;
  singularLowerCase: LocalesKey;
  new: LocalesKey;
  create: LocalesKey;
  createMultiple: LocalesKey;
  current: LocalesKey;
  changeStatus: LocalesKey;
  create_new: LocalesKey;
  view: LocalesKey;
  version: LocalesKey;
  valid: LocalesKey;
  nonValid: LocalesKey;
  noSmartContracts: LocalesKey;
  viewPlural: LocalesKey;
  name: LocalesKey;
  description: LocalesKey;
  downloadResults: LocalesKey;
  displayOptions: LocalesKey;
  areYouSureYouWantLeaveTaskThisActionCannotBeUndone: LocalesKey;
  add: LocalesKey;
  addDescription: LocalesKey;
  addNotes: LocalesKey;
  addName: LocalesKey;
  buttons: {
    abort: LocalesKey;
    accept: LocalesKey;
    approve: LocalesKey;
    cancel: LocalesKey;
    checkIn: LocalesKey;
    close: LocalesKey;
    pause: LocalesKey;
    pendingMoreInfo: LocalesKey;
    restore: LocalesKey;
    restart: LocalesKey;
    sendToRevision: LocalesKey;
    startTask: LocalesKey;
  };
  canceledPluralLowerCase: LocalesKey;
  canceledLowerCase: LocalesKey;
  doneLowerCase: LocalesKey;
  editDescription: LocalesKey;
  editNotes: LocalesKey;
  editVersion: LocalesKey;
  goTo: LocalesKey;
  goToProject: LocalesKey;
  import: LocalesKey;
  importAllocate: LocalesKey;
  tasksIn: LocalesKey;
  invitedUsers: LocalesKey;
  inviteToMultipleTasks: LocalesKey;
  inviteUser: LocalesKey;
  invitations: LocalesKey;
  isClosed: LocalesKey;
  jobStatus: {
    start: LocalesKey;
    cancel: LocalesKey;
    accept: LocalesKey;
    processing: LocalesKey;
  };
  myFinance: LocalesKey;
  myNotes: LocalesKey;
  myResults: LocalesKey;
  myTimeline: LocalesKey;
  namesHaveToBeUnique: LocalesKey;
  noPlural: LocalesKey;
  pleaseRate: LocalesKey;
  processingJobs: LocalesKey;
  finishTasks: LocalesKey;
  finishMultipleTasks: LocalesKey;
  results: LocalesKey;
  roundCounter: LocalesKey;
  singularAttachments: LocalesKey;
  showHiddenTasks: LocalesKey;
  showOnlyHiddenTasks: LocalesKey;
  showCanceledTasks: LocalesKey;
  showDoneTasks: LocalesKey;
  allAttachments: LocalesKey;
  multiple: LocalesKey;
  mute: LocalesKey;
  missingSourceFiles: LocalesKey;
  my: LocalesKey;
  noVisibleTasksFound: LocalesKey;
  notes: LocalesKey;
  search: LocalesKey;
  searchByTaskName: LocalesKey;
  searchTasksByNameOrUser: LocalesKey;
  selectSingular: LocalesKey;
  sendInvoice: LocalesKey;
  setDueDate: LocalesKey;
  selectShareToCheckInWith: LocalesKey;
  status: LocalesKey;
  states: {
    qa: LocalesKey;
    buttons: {
      accept: LocalesKey;
      approve: LocalesKey;
      cancel: LocalesKey;
      checkIn: LocalesKey;
      pause: LocalesKey;
      pendingMoreInfo: LocalesKey;
      restore: LocalesKey;
      restart: LocalesKey;
      sendToRevision: LocalesKey;
    };
  };
  statusChange: LocalesKey;
  submitAsTaskResult: LocalesKey;
  submitResults: LocalesKey;
  submitAsSourceFile: LocalesKey;
  submitSourceFiles: LocalesKey;
  submittedResults: LocalesKey;
  selectTaskToSeeMessages: LocalesKey;
  startNewVersion: LocalesKey;
  tableSettings: LocalesKey;
  timeline: LocalesKey;
  toProject: LocalesKey;
  findResultsInAttachments: LocalesKey;
  optionalWhenYouExpectTheTaskToBeFinished: LocalesKey;
  leave: LocalesKey;
  tags: LocalesKey;
  pluralLowerCase: LocalesKey;
  taskName: LocalesKey;
  tasksInProgress: LocalesKey;
  taskTeam: LocalesKey;
  team: LocalesKey;
  total: LocalesKey;
  trackTaskTime: LocalesKey;
  trackedToday: LocalesKey;
  trackTimeInMultipleTasks: LocalesKey;
  trackTime: LocalesKey;
  track: LocalesKey;
  thisTaskIsHidden: LocalesKey;
  unhide: LocalesKey;
  optional: LocalesKey;
  caution: LocalesKey;
  addedToProcessing: LocalesKey;
  goToSmartContract: LocalesKey;
  goToProfileSettings: LocalesKey;
  withoutSourceFiles: LocalesKey;
  workload: LocalesKey;
  myWithoutSourceFiles: LocalesKey;
  youCanNotStartTheTaskWithRefundedInvoices: LocalesKey;
  youWillNoLongerReceiveNotificationsRelatedToThisTask: LocalesKey;
  theTaskWillBeExcludedFromYourMyTasksList: LocalesKey;
  itWontBeIncludedInSearchResultsFilteredByYouAsATaskMember: LocalesKey;
  inviteTeamMembersToTheTaskUponTaskCreation: LocalesKey;
}

export const tasksKeys = keyPathMirror<TasksKeyType, string>(
  {
    addTags: null,
    all: null,
    alreadyMember: null,
    acceptResults: null,
    active: null,
    areYouSureYouWantToDeleteThisTaskYouWillNotBeAbleToUndoThisAction: null,
    areYouCertainAboutLeavingTheTaskPleaseBeAwareOfTheFollowingOutcomes: null,
    areYouSureYouWantToStartTheTask: null,
    cell: null,
    channels: null,
    confirmAndStart: null,
    cannotStartTaskWithNoItemsBoundToTheCurrentSmartContract: null,
    cannotCheckInTheSmartContractWithNoShares: null,
    cautionItemsInSmartContractAreNotBoundToTheDepositInvoiceAndYouShallStartTheTaskAtYourOwnRisk:
      null,
    coefficient: null,
    duplicate: null,
    finalResult: null,
    fullyPaid: null,
    id: null,
    hide: null,
    list: null,
    plural: null,
    singular: null,
    singularLowerCase: null,
    new: null,
    changeStatus: null,
    create: null,
    createMultiple: null,
    create_new: null,
    current: null,
    view: null,
    version: null,
    valid: null,
    nonValid: null,
    noSmartContracts: null,
    viewPlural: null,
    name: null,
    description: null,
    downloadResults: null,
    displayOptions: null,
    add: null,
    areYouSureYouWantLeaveTaskThisActionCannotBeUndone: null,
    addDescription: null,
    addNotes: null,
    addName: null,
    buttons: {
      abort: null,
      accept: null,
      approve: null,
      cancel: null,
      checkIn: null,
      close: null,
      pause: null,
      pendingMoreInfo: null,
      restore: null,
      restart: null,
      sendToRevision: null,
      startTask: null
    },
    canceledPluralLowerCase: null,
    canceledLowerCase: null,
    doneLowerCase: null,
    editDescription: null,
    editNotes: null,
    editVersion: null,
    goTo: null,
    goToProject: null,
    tasksIn: null,
    invitedUsers: null,
    inviteToMultipleTasks: null,
    inviteUser: null,
    invitations: null,
    import: null,
    importAllocate: null,
    isClosed: null,
    jobStatus: {
      start: null,
      cancel: null,
      accept: null,
      processing: null
    },
    myFinance: null,
    myNotes: null,
    myResults: null,
    myTimeline: null,
    namesHaveToBeUnique: null,
    noPlural: null,
    pleaseRate: null,
    processingJobs: null,
    finishTasks: null,
    finishMultipleTasks: null,
    results: null,
    roundCounter: null,
    search: null,
    searchByTaskName: null,
    searchTasksByNameOrUser: null,
    selectSingular: null,
    sendInvoice: null,
    setDueDate: null,
    selectShareToCheckInWith: null,
    singularAttachments: null,
    showHiddenTasks: null,
    showOnlyHiddenTasks: null,
    showCanceledTasks: null,
    showDoneTasks: null,
    allAttachments: null,
    multiple: null,
    mute: null,
    missingSourceFiles: null,
    my: null,
    noVisibleTasksFound: null,
    notes: null,
    states: {
      qa: null,
      buttons: {
        accept: null,
        approve: null,
        cancel: null,
        checkIn: null,
        pause: null,
        pendingMoreInfo: null,
        restore: null,
        restart: null,
        sendToRevision: null
      }
    },
    status: null,
    statusChange: null,
    submitAsTaskResult: null,
    submitResults: null,
    submitAsSourceFile: null,
    submitSourceFiles: null,
    submittedResults: null,
    selectTaskToSeeMessages: null,
    startNewVersion: null,
    toProject: null,
    findResultsInAttachments: null,
    optionalWhenYouExpectTheTaskToBeFinished: null,
    leave: null,
    tableSettings: null,
    timeline: null,
    tags: null,
    taskName: null,
    tasksInProgress: null,
    taskTeam: null,
    pluralLowerCase: null,
    team: null,
    total: null,
    trackTaskTime: null,
    trackedToday: null,
    trackTimeInMultipleTasks: null,
    trackTime: null,
    track: null,
    thisTaskIsHidden: null,
    unhide: null,
    optional: null,
    caution: null,
    addedToProcessing: null,
    goToSmartContract: null,
    goToProfileSettings: null,
    withoutSourceFiles: null,
    workload: null,
    myWithoutSourceFiles: null,
    youCanNotStartTheTaskWithRefundedInvoices: null,
    youWillNoLongerReceiveNotificationsRelatedToThisTask: null,
    theTaskWillBeExcludedFromYourMyTasksList: null,
    itWontBeIncludedInSearchResultsFilteredByYouAsATaskMember: null,
    inviteTeamMembersToTheTaskUponTaskCreation: null
  },
  'models.tasks.'
);
