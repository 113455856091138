export default {
  plural: 'Мітки',
  singular: 'Мітка',
  new: 'Нова мітка',
  add: 'Додати мітки',
  change: 'Змінити мітки',
  select: 'Обрати мітки...',
  removeAllOtherTags: 'Видалити усі інші теги',
  no: 'Мітки відсутні'
};
