export default {
  plural: 'Tags',
  pluralDowncase: 'tags',
  singular: 'Tag',
  new: 'New Tag',
  add: 'Add tags',
  change: 'Change tags',
  customTags: 'Custom tags',
  select: 'Select tags...',
  removeAllOtherTags: 'Remove all other tags',
  no: 'No tags'
};
